<template>
  <section>
    <v-row>
      <v-col cols="12" xl="9" lg="11">
        <v-tabs v-model="tab" color="success" background-color="transparent">
          <v-tab v-for="(item, i) in tabs" :key="i" class="button">
            {{item}}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" style="background: transparent">
          <v-tab-item v-for="(item, i) in tabs" :key="i" >
            <Leaderboard v-if="item === 'Leaderboard'" 
            :leaderboards="leaderboards"/>
            <Raffle v-if="item === 'E Raffle'" 
            :raffles="raffles"/>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Leaderboard from '../components/gaming/Leaderboard.vue'
import Raffle from '../components/gaming/Raffle.vue'

export default {
  components: {
    Leaderboard,
    Raffle
  },
  data: () => ({
    tab: null,
    tabs: ['Leaderboard', 'E Raffle']
  }),
  computed: {
    ...mapState('admin', {
      leaderboards: (state) => state.leaderboards,
      raffles: (state) => state.raffles,
    })
  },
  mounted() {
    this.getLeaderboard()
    this.getRaffle()
  },
  methods: {
    ...mapActions('admin', [
      'getLeaderboard',
      'getRaffle',
    ])
  }
}
</script>