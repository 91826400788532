<template>
  <section>
    <div class="col-lg-4">
      <v-card> 
        <v-card-title class="body-2">
          New Leaderboard
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-form @submit.prevent="save" ref="addForm">
            <small>TITLE</small>
            <v-text-field dense v-model="form.title" solo/>
            <small>USER TYPE</small>
            <v-select v-model="form.type" 
            :items="$helpers.userLevel()"
            item-text="text"
            item-value="value"
            multiple
            chips
            dense
            clearable
            solo/>
            <div class="flex d-flex justify-end">
              <div class="d-flex">
                <div class="mx-2">
                  <v-btn type="submit" small class="button">Save</v-btn>
                </div>
                <div class="">
                  <v-btn small class="button">Cancel</v-btn>
                </div>
              </div>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <div class="col-lg-8" v-if="leaderboards.length > 0">
      <v-data-table
        :headers="headers"
        :items="leaderboards"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:[`item.user_type`]="{ item }">
          <div class="caption" v-html="retype(item.user_type)"></div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div class="caption">
            <v-chip small>{{item.status ? 'Done' : 'Ongoing'}}</v-chip>
          </div>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn icon @click="view(item.id)">
            <v-icon small>
              mdi-eye-circle-outline
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
var CryptoJS = require("crypto-js");

export default {
  props: ['leaderboards'],
  data: () => ({
    form: {
      title: '',
      type: ''
    },
    headers: [
      { text: 'TITLE', align: 'start', value: 'title' },
      { text: 'USER TYPE', value: 'user_type' },
      { text: 'STATUS', value: 'status' },
      { text: '', value: 'action' },
    ]
  }),
  methods: {
    ...mapActions('admin', [
      'storeLeaderboard'
    ]),

    save() {
      this.storeLeaderboard(this.form)
      .then(() => {
        this.$refs.addForm.reset()
      })
    },

    view(id) {
      console.log(id);
        let key = CryptoJS.AES.encrypt(id.toString(), "secret").toString()
      this.$router.push({
        name: 'Admin Leaderboard',
        params: {
          id: key
        }
      })
    },  

    retype(item) {
      // console.log(item);
      let arr = JSON.parse(item)
      let html = ''
      arr.forEach((el) => {
        console.log(el);
        html += `${this.$helpers.level(el)}`
        html += `, `
      });

      return html;
    }

  }

}
</script>
